// @ts-check
import {makeStyles} from "tss-react/mui";

export default makeStyles()((/** @type AllCustomTheme */ theme) => ({
    root: {
        position: "absolute",
        cursor: "pointer",
        borderRadius: "5px",
        zIndex: theme.custom.zIndex.canvas + 25,
        border: "2px solid transparent",
        background: "transparent"
    },
    innerRoot: {
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "5px",
        boxShadow: theme.shadows[2],
        height: "100%",
        overflow: "hidden"
    },
    rootHovered: {
        zIndex: theme.custom.zIndex.canvas + 35
    },
    rootBackground: {
        "backgroundColor": "#fff",
        "&:hover": {
            border: `solid 2px ${theme.palette.primary.main}`
        }
    },
    rootBackgroundActive: {
        "backgroundColor": theme.custom.active.light,
        "&:hover": {
            border: `solid 2px ${theme.palette.primary.main}`
        }
    },
    rootEmergency: {
        "color": theme.custom.emergency.main,
        "background": theme.custom.emergency.light,
        "&:hover": {
            border: `solid 2px ${theme.custom.emergency.main}`
        }
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden"
    },
    titleWrapper: {
        borderBottom: "1px dotted " + theme.palette.grey[500],
        padding: "0.25rem"
    },
    title: {
        display: "flex",
        fontWeight: 500,
        width: "100%",
        fontSize: "12px",
        alignItems: "center",
        justifyContent: "space-between"
    },
    startAndName: {
        display: "flex",
        width: "100%"
    },
    patient: {
        display: "flex",
        fontWeight: 500,
        width: "100%",
        fontSize: "12px"
    },
    patientInner: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    cpb: {
        display: "block",
        fontSize: "10px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    patientName: {
        paddingLeft: "6px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: theme.typography.fontWeightBold
    },
    subtitle1: {
        fontWeight: 300,
        fontSize: "10px",
        lineHeight: "1.25em",
        padding: 0,
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        display: "-webkit-box",
        height: "26px",
        margin: "5px 4px",
        fontStyle: "italic",
        flexShrink: 0
    },
    subtitle2: {
        fontWeight: 300,
        fontSize: "10px",
        lineHeight: "1",
        borderTop: "1px dotted " + theme.palette.grey[500],
        padding: "8px 4px"
    },
    seperatorLine: {
        borderBottom: "1px dotted " + theme.palette.grey[500]
    },
    opStatus: {
        minWidth: "0.4rem",
        width: "0.4rem",
        backgroundColor: theme.palette.grey[200]
    },
    opDisciplineBar: {
        minWidth: "0.25rem",
        width: "0.25rem"
    },
    opStatusStart: {
        backgroundColor: theme.custom.preparation.main,
        width: "100%"
    },
    opStatusCenter: {
        backgroundColor: theme.custom.progress.main,
        width: "100%"
    },
    opStatusEnd: {
        backgroundColor: theme.custom.post.main,
        width: "100%"
    },
    selectedOp: {
        border: "solid 2px " + theme.palette.primary.main
    },
    smallSelectedOp: {
        border: "solid 1px " + theme.palette.primary.main
    },
    buttomIcons: {
        "position": "absolute",
        "right": "0.5rem",
        "bottom": "4px",
        "display": "flex",
        "justifyContent": "flex-end",
        "& svg": {
            width: "18px",
            height: "18px",
            background: "#f7f7f7",
            borderRadius: "50%"
        }
    },
    checkIcon: {
        color: theme.palette.success.main,
        padding: "1px"
    },
    lockIcon: {
        marginLeft: "2px",
        color: theme.palette.text.secondary
    },
    overlapIcon: {
        position: "absolute",
        zIndex: theme.custom.zIndex.canvas + 50,
        height: "18px",
        cursor: "pointer"
    },

    overlapActive: {
        background: theme.palette.warning.light
    }
}));
