// @ts-check
import {t} from "i18next";
import {object, string} from "prop-types";
import React, {useContext} from "react";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {formatBirthDate} from "../../../utils/format_birth_date";
import {formatObservation} from "../../../utils/patient_helpers";
import {selectPatientBirthDate, selectPatientGender} from "../../private_data/private_data_selectors";
import useStyles from "../op_box_vertical.styles";

/**
 * render PatientDetails component
 * @param {Object} props
 * @param {string} props.id
 * @param {ObservationsPatient} props.observations
 * @return {React.ReactElement}
 */
const PatientDetails = ({id, observations}) => {
    const {classes} = useStyles();
    const {getLuxonToken} = useContext(DateContext);

    const {
        TEXT_PUNCTUATION: {HYPHEN, VERTICAL_SLASH}
    } = config;

    const patientBirthDate = useSelector((state) => selectPatientBirthDate(state, {id}));
    const patientGender = useSelector((state) => selectPatientGender(state, {id}));

    const birthDate = formatBirthDate(patientBirthDate, getLuxonToken(DATE_FORMATS.BIRTH_DATE_FORMAT));
    const gender = patientGender ? t(`GenderShort.${patientGender}`) : HYPHEN;
    const {weight, height: patientHeight} = observations || {};

    const patient = [birthDate, gender, formatObservation(weight), formatObservation(patientHeight)];

    return (
        <div className={classes.patient}>
            <span className={classes.patientInner} data-testid={"patientInfo"}>
                {patient.join(` ${VERTICAL_SLASH} `)}
            </span>
        </div>
    );
};
PatientDetails.propTypes = {
    id: string.isRequired,
    observations: object.isRequired
};
export default PatientDetails;
