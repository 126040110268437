// @ts-check
import PropTypes from "prop-types";
import React from "react";

import {inProgressStatus} from "../../../config/op_status";
import {OpBoxVertical} from "./op_box_vertical";

/**
 *
 * @param {Object} props
 * @param {String} props.opId
 * @param {Boolean} props.isSelectedOp
 * @param {Boolean} props.emergency
 * @param {Number} props.topSeparation
 * @param {Number} props.height
 * @param {OpBoxVerticalContent} props.content
 * @param {String} props.status
 * @param {Function} props.handleOpenDetails
 * @param {boolean} props.hasOverlap
 * @param {number} props.adjustIndex
 * @return {React.ReactElement}
 */
export const OpBoxVerticalWrapper = ({
    opId,
    isSelectedOp,
    emergency,
    topSeparation,
    height,
    content,
    status,
    handleOpenDetails,
    hasOverlap,
    adjustIndex
}) => {
    /** @type {DynamicClasses} */
    const dynamicClasses = {backgroundClass: "rootBackground", selectedClass: "", rootEmergency: ""};
    if (status === inProgressStatus) {
        dynamicClasses.backgroundClass = "rootBackgroundActive";
    }

    if (emergency) {
        dynamicClasses.rootEmergency = "rootEmergency";
    }

    if (isSelectedOp) {
        dynamicClasses.selectedClass = "selectedOp";
    }

    return (
        <OpBoxVertical
            adjustIndex={adjustIndex}
            content={content}
            dynamicClasses={dynamicClasses}
            handleOpenDetails={handleOpenDetails}
            hasOverlap={hasOverlap}
            height={height}
            opId={opId}
            top={topSeparation}
        />
    );
};

OpBoxVerticalWrapper.propTypes = {
    opId: PropTypes.string.isRequired,
    isSelectedOp: PropTypes.bool.isRequired,
    emergency: PropTypes.bool.isRequired,
    topSeparation: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    content: PropTypes.shape({
        team: PropTypes.object.isRequired,
        patient: PropTypes.object.isRequired,
        intervention: PropTypes.string.isRequired,
        hexDiscipline: PropTypes.string.isRequired
    }).isRequired,

    handleOpenDetails: PropTypes.func.isRequired,
    hasOverlap: PropTypes.bool.isRequired,
    adjustIndex: PropTypes.number.isRequired
};

export default React.memo(OpBoxVerticalWrapper);
