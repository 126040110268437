// @ts-check
import {object} from "prop-types";
import React from "react";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {formatOpTeam} from "../../../utils/format_op_team";
import {selectStandardNamesArray} from "../../private_data/private_data_selectors";
import useStyles from "../op_box_vertical.styles";

/**
 * render OpTeam component
 * @param {Object} props
 * @param {OpTeam} props.team
 * @param {SurgeonPresenting} props.surgeonPresenting
 * @return {React.ReactElement}
 */
const OpTeam = ({team, surgeonPresenting}) => {
    const {classes} = useStyles();

    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    const allPractitionerIds = [].concat(...Object.values(team));
    if (surgeonPresenting?.reference) {
        allPractitionerIds.push(surgeonPresenting.reference);
    }
    const teamNameList = useSelector(selectStandardNamesArray({ids: allPractitionerIds, type: "practitioner"}));

    const hasPractitionerIds = !!allPractitionerIds.length;
    const teamNames = hasPractitionerIds ? formatOpTeam(team, teamNameList, surgeonPresenting) : HYPHEN;

    return (
        <div className={classes.subtitle2} data-testid={"teamNames"} title={teamNames}>
            {teamNames}
        </div>
    );
};
OpTeam.propTypes = {
    team: object.isRequired,
    surgeonPresenting: object
};
export default OpTeam;
