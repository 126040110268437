// @ts-check
import {CheckCircle, Lock} from "@mui/icons-material";
import {Box, ClickAwayListener} from "@mui/material";
import {bool, func, number, object, string} from "prop-types";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../config/config.json";
import {setClickedOp, setHoveredOp} from "../../pages/day_view/day_view_actions";
import {selectClickedOp, selectHoveredOp} from "../../pages/day_view/day_view_selectors";
import {selectFeSettings} from "../fe_settings/fe_settings_selectors";
import {selectFullName} from "../private_data/private_data_selectors";
import {Cpb} from "../shared/cpb";
import OpOverlapIcon from "../shared/icons/op_overlap_icon";
import Ward from "../shared/ward/ward";
import OpTeam from "./components/op_team";
import PatientDetails from "./components/patient_details";
import useStyles from "./op_box_vertical.styles";
const CPB = "CPB";

/**
 * Render OpBoxVertical component in DayView
 * @param {Object} props
 * @param {OpBoxVerticalContent} props.content
 * @param {Number} props.height
 * @param {Number} props.top
 * @param {DynamicClasses} props.dynamicClasses
 * @param {String} props.opId
 * @param {Function} props.handleOpenDetails
 * @param {boolean} props.hasOverlap
 * @param {number} props.adjustIndex
 * @return {React.ReactElement}
 */
export const OpBoxVertical = ({content, height, top, dynamicClasses, opId, handleOpenDetails, hasOverlap, adjustIndex}) => {
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();
    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    // redux store

    const {additionalOpInfo} = useSelector(selectFeSettings);

    const hoveredOp = useSelector(selectHoveredOp);
    const clickedOp = useSelector(selectClickedOp);

    // Prepare names
    const patientName = useSelector(selectFullName({id: content.patient?.id, type: "patient"}));
    const patientNameFormatted = patientName || HYPHEN;

    const hasCpb = additionalOpInfo && additionalOpInfo.includes(CPB);

    // handlers

    const handleMouseLeave = () => {
        dispatch(setHoveredOp(""));
    };

    const handleMouseEnter = () => {
        dispatch(setHoveredOp(opId));
    };

    const handleClickOverlapIcon = (e) => {
        e.stopPropagation();
        dispatch(setClickedOp(opId));
    };

    const handleClickaway = () => {
        if (clickedOp === opId) {
            dispatch(setClickedOp(""));
        }
    };

    const adjustTop = adjustIndex > 0 ? adjustIndex * 16 : 0;
    return (
        <ClickAwayListener onClickAway={handleClickaway}>
            <Box>
                {hasOverlap && (
                    <Box
                        className={classes.overlapIcon}
                        left={"2%"}
                        top={top - 4 + adjustTop + "px"}
                        onClick={handleClickOverlapIcon}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <OpOverlapIcon style={cx({[classes.overlapActive]: clickedOp === opId})} />
                    </Box>
                )}
                <Box
                    aria-label="opBoxVertical"
                    className={cx(
                        classes.root,
                        classes[dynamicClasses.backgroundClass],
                        classes[dynamicClasses.rootEmergency],
                        classes[dynamicClasses.selectedClass],
                        {[classes.rootHovered]: hoveredOp === opId || clickedOp === opId}
                    )}
                    data-opid={opId}
                    data-testid="opBoxVertical"
                    height={height + "px"}
                    left={"5%"}
                    role="region"
                    top={top + "px"}
                    width={"90%"}
                    onClick={(e) => handleOpenDetails({opId: opId, currentTarget: e.currentTarget})}
                >
                    <div className={cx(classes.innerRoot)}>
                        <div
                            className={classes.opDisciplineBar}
                            data-testid="disciplineLine"
                            style={{backgroundColor: content.hexDiscipline}}
                        />
                        <div className={classes.content}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.title}>
                                    <div className={classes.startAndName}>
                                        <span data-testid={"startTime"}>{content.startOpTime}</span>
                                        <span className={classes.patientName} data-testid={"patientName"} title={patientNameFormatted}>
                                            {patientNameFormatted}
                                        </span>
                                    </div>
                                </div>
                                <PatientDetails id={content.patient?.id} observations={content.patient?.observations} />
                                {hasCpb && <Cpb duraCpb={content.duraCpb} styles={classes.cpb} />}
                            </div>
                            <div className={classes.subtitle1} title={content.intervention}>
                                {content.intervention}
                            </div>
                            <OpTeam surgeonPresenting={content.surgeonPresenting} team={content.team} />
                            <div className={classes.subtitle2}>
                                <Ward patientLocations={content?.patient?.locations} />
                            </div>
                        </div>
                        <div className={classes.buttomIcons}>
                            {content.isMedicalClearanceDone && <CheckCircle className={classes.checkIcon} data-testid="clearedIcon" />}
                            {content.isLocked && <Lock className={classes.lockIcon} data-testid="lockedIcon" />}
                        </div>
                        <div className={classes.opStatus}>
                            <div className={classes.opStatusStart} style={{height: content.durationPrePercent + "%"}}></div>
                            <div className={classes.opStatusCenter} style={{height: content.durationOpPercent + "%"}}></div>
                            <div className={classes.opStatusEnd} style={{height: content.durationPostPercent + "%"}}></div>
                        </div>
                    </div>
                </Box>
            </Box>
        </ClickAwayListener>
    );
};

OpBoxVertical.propTypes = {
    opId: string.isRequired,
    top: number.isRequired,
    height: number.isRequired,
    dynamicClasses: object.isRequired,
    content: object.isRequired,
    handleOpenDetails: func.isRequired,
    hasOverlap: bool.isRequired,
    adjustIndex: number.isRequired
};

export default React.memo(OpBoxVertical);
